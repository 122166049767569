
::v-deep {
    td.text-align-left {
        text-align: left !important;
    }
    .v-expansion-panel-content__wrap {
        padding: 0 0 30px;
    }

    .product-item-header {
        flex-direction: row-reverse;
    }
}
